<template>
    <ContentWrapper>
        <breadcrumb icon="fad fa-images" name="Marketing"/>

        <div class="container">

            <div class="card card-default mb-3 animated-fast fadeInDown">
                <div class="card-header">{{$t('marketing.view.invite.links.label')}}<i class="fad fa-envelope-open-text card-header-icon"></i></div>
                <div class="card-body">
                    <b-row>
                        <b-col md="6" class="mt-2">
                            <small>{{$t('marketing.view.invite.links.register.heading')}}</small>
                            <b-input-group @click="copy(getLink(ref_links.register.code))" v-b-tooltip.hover :title="$t('marketing.view.invite.links.register.description')">
                                <b-input onClick="this.select();" ref="refLinkRegister" v-bind:value="getLink(ref_links.register.code)" readonly></b-input>
                                <b-input-group-append>
                                    <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="float-right">
                                <span>{{ref_links.register.clicks}} Clicks</span>
                                <span v-show="ref_links.register.registrations"> | {{ref_links.register.registrations }} Registrations</span>
                                <span v-show="ref_links.register.sales"> | {{ref_links.register.sales }} Sales</span>
                            </small>
                        </b-col>
                        <b-col md="6" class="mt-2">
                            <small>{{$t('marketing.view.invite.links.landing.heading')}}</small>
                            <b-input-group @click="copy(getLink(ref_links.landing.code))" v-b-tooltip.hover :title="$t('marketing.view.invite.links.landing.description')">
                                <b-input onClick="this.select();" ref="refLinkLanding" v-bind:value="getLink(ref_links.landing.code)" readonly></b-input>
                                <b-input-group-append>
                                    <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="float-right">
                                <span>{{ref_links.landing.clicks}} Clicks</span>
                                <span v-show="ref_links.landing.registrations"> | {{ref_links.landing.registrations }} Registrations</span>
                                <span v-show="ref_links.landing.sales"> | {{ref_links.landing.sales }} Sales</span>
                            </small>
                        </b-col>
                        <b-col md="6" class="mt-2">
                            <small>{{$t('marketing.view.invite.links.website.heading')}}</small>
                            <b-input-group @click="copy(getLink(ref_links.website.code))" v-b-tooltip.hover :title="$t('marketing.view.invite.links.website.description')">
                                <b-input onClick="this.select();" ref="refLinkWebsite" v-bind:value="getLink(ref_links.website.code)" readonly></b-input>
                                <b-input-group-append>
                                    <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="float-right">
                                <span>{{ref_links.website.clicks}} Clicks</span>
                                <span v-show="ref_links.website.registrations"> | {{ref_links.website.registrations }} Registrations</span>
                                <span v-show="ref_links.website.sales"> | {{ref_links.website.sales }} Sales</span>
                            </small>
                        </b-col>
                        <b-col md="6" class="mt-2">
                            <small>{{$t('marketing.view.invite.links.shop.heading')}}</small>
                            <b-input-group @click="copy(getLink(ref_links.shop.code))" v-b-tooltip.hover :title="$t('marketing.view.invite.links.shop.description')">
                                <b-input onClick="this.select();" ref="refLinkShop" v-bind:value="getLink(ref_links.shop.code)" readonly></b-input>
                                <b-input-group-append>
                                    <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <small class="float-right">
                                <span>{{ref_links.shop.clicks}} Clicks</span>
                                <span v-show="ref_links.shop.registrations"> | {{ref_links.shop.registrations }} Registrations</span>
                                <span v-show="ref_links.shop.sales"> | {{ref_links.shop.sales }} Sales</span>
                            </small>
                        </b-col>
                        <b-col offset-md="6" md="6" class="mt-2 text-right">
                            <span class="mr-2">{{$t('marketing.view.invite.links.short')}}</span>

                            <label class="switch d-inline">
                                <input type="checkbox" v-model="short_links" checked="checked"/>
                                <span></span>
                            </label>
                        </b-col>
                    </b-row>

                    <hr v-if="ref_links.custom.length">

                    <b-row>
                        <b-col md="6" class="mt-2" v-for="ref_link in ref_links.custom" :key="ref_link.code">
                            <small>{{ ref_link.description || ref_link.link }}</small>

                            <b-input-group v-show="!ref_link.edit_mode">
                                <b-input @click="copy(getLink(ref_link.code))" ref="refLinkShop" v-bind:value="getLink(ref_link.code)" readonly></b-input>
                                <b-input-group-append>
                                    <b-button @click="copy(getLink(ref_link.code))" variant="default"><i class="fad fa-clipboard"></i></b-button>
                                </b-input-group-append>
                                <b-input-group-append>
                                    <b-button @click="$set(ref_link, 'edit_mode', true)" variant="default"><i class="fad fa-pencil"></i></b-button>
                                </b-input-group-append>
                                <b-input-group-append>
                                    <b-button :href="ref_link.link" target="_blank" variant="default"><i class="fad fa-external-link"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-input-group v-show="ref_link.edit_mode">
                                <b-input v-model="ref_link.description" type="text" placeholder="Enter a internal description for this link"></b-input>
                                <b-input-group-append>
                                    <b-button @click="save(ref_link)" variant="default"><i class="fad fa-save"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <small v-if="ref_link.last_used_at">Last click: {{ref_link.last_used_at | moment('calendar')}}</small>
                            <small class="float-right">
                                <span>{{ref_link.clicks}} Clicks</span>
                                <span v-show="ref_link.registrations"> | {{ref_link.registrations }} Registrations</span>
                                <span v-show="ref_link.sales"> | {{ref_link.sales }} Sales</span>
                            </small>
                        </b-col>
                        <b-col md="6" class="mt-2">
                            <br/>
                            <b-btn class="mb-2" @click="show_link_creator = !show_link_creator">Create new link <i class="fad fa-link ml-1"></i></b-btn>

                            <div class="list list-group" v-show="show_link_creator">
                                <a href="" @click.prevent="createReferralLink('register')" class="list-group-item-action list-group-item"><i class="fad fa-key mr-2"></i> Register</a>
                                <a href="" @click.prevent="createReferralLink('landing')" class="list-group-item-action list-group-item"><i class="fad fa-browser mr-2"></i> Landingpage</a>
                                <a href="" @click.prevent="createReferralLink('website')" class="list-group-item-action list-group-item"><i class="fad fa-browser mr-2"></i> Website</a>
                                <a href="" @click.prevent="createReferralLink('custom')" class="list-group-item-action list-group-item"><i class="fad fa-user-edit mr-2"></i> Custom</a>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <ul class="nav nav-pills my-3 animated-fast fadeInLeft">
                <li class="nav-item">
                    <router-link active-class="active" :to="{name: 'Marketing:Logo'}" class="nav-link py-1 mr-2" href=""><i class=" mr-2 fad fa-images"></i>Logo</router-link>
                </li>
            </ul>

            <router-view></router-view>

        </div>
    </ContentWrapper>
</template>
<script>

    export default {
        components: {},
        data() {
            return {
                show_link_creator: false,
                ref_links: {
                    website: {
                        code: '',
                        clicks: 0
                    },
                    register: {
                        code: '',
                        clicks: 0
                    },
                    landing: {
                        code: '',
                        clicks: 0
                    },
                    shop: {
                        code: '',
                        clicks: 0
                    },
                    custom: [],
                    prefix: '',
                    prefix_short: ''
                },
                short_links: false
            }
        },
        beforeCreate() {
            this.$api.get('user/team/referral-links').then((response) => {
                this.ref_links.landing = response.data.referral_link_landing;
                this.ref_links.register = response.data.referral_link_register;
                this.ref_links.website = response.data.referral_link_website;
                this.ref_links.shop = response.data.referral_link_shop;
                this.ref_links.custom = response.data.referral_links_custom;

                this.ref_links.prefix = process.env.VUE_APP_REFERRAL_URL;
                this.ref_links.prefix_short = process.env.VUE_APP_REFERRAL_SHORT_URL;
            });
        },
        methods: {
            createReferralLink(type) {
                this.show_link_creator = false;
                if (type === 'custom') {
                    this.$swal.fire({
                        title: 'Enter target url',
                        input: 'text',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true,
                        confirmButtonText: 'Create',
                        reverseButtons: true,
                        inputPlaceholder: 'https://cannerald.com/blog/post/the-swiss-shop-is-online'
                    }).then(response => {
                        if (response.value) {
                            this.$api.post('user/team/referral-links/create', {
                                type: 'custom',
                                link: response.value
                            }).then(response => {
                                this.ref_links.custom.push(response.data.data);
                            }).catch(response => {
                                if (response.status === 422) {
                                    this.$swal.fire(response.data.message, response.data.errors.link[0], 'warning');
                                }
                            });
                        }
                    })
                } else {
                    this.$api.post('user/team/referral-links/create', {
                        type: type
                    }).then(response => {
                        this.ref_links.custom.push(response.data.data);
                    })
                }
            },
            save(ref_link) {
                this.$set(ref_link, 'edit_mode', false);
                this.$api.post('user/team/referral-link/' + ref_link.code, ref_link);
            },
            getLink(code) {
                return (this.short_links ? this.ref_links.prefix_short : this.ref_links.prefix) + code
            },
            copy(val) {
                this.$copyText(val);
                this.$notify({
                    group: 'main',
                    title: 'Ref-Link copied!',
                    type: 'success',
                    data: {
                        class: 'far fa-clipboard',
                    }
                });
            },
        },
    }
</script>